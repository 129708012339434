<template>
  <v-main>

    <v-container
        id="update-password-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-lock-reset-outline"
          title="Edit user"
          class="px-5 py-3"
          ref="form"
      >

        <v-card-text>

          <v-card-text v-if="errors.length">
            <v-alert color="error"
                     dark
                     outlined
                     :key="error.id"
                     show variant="danger"
                     v-for="error in errors">
              {{ error }}
            </v-alert>
          </v-card-text>
          
          <v-alert v-if="success"
                   color="success"
                   dark
                   outlined>
            Your password has been changed successfully.
          </v-alert>

          <v-form ref="updatePasswordForm"
                  v-model="valid"
                  lazy-validation
                  v-if="!success"
                  @submit.prevent="submit"
                  @submit="validate"
          >

            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="currentPassword"
                    label="Current Password"
                    type="password"
                    validate-on-blur
                    :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>

              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="newPassword"
                    label="New Password"
                    type="password"
                    validate-on-blur
                    :rules="rules.newPassword"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    v-model="confirmNewPassword"
                    label="Re-Enter New Password"
                    type="password"
                    :rules="rules.confirmNewPassword"
                ></v-text-field>
              </v-col>
            </v-row>


            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <div class="my-2">
                  <v-btn
                      color="primary"
                      dark
                      type="submit"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>

          </v-form>


        </v-card-text>

      </base-material-card>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import validation from '@/utils/validation'
import constants from '@/utils/constants'
import {mapGetters} from "vuex";

export default {
  name: 'CoreUpdatePasswordView',

  created() {
    this.userId = this.getUserId
  },

  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    })
  },

  methods: {
    validate () {
      this.errors = []
      if(this.$refs.updatePasswordForm.validate()) {
        this.updatePassword()
      }
    },
    updatePassword() {
      this.$gtag.event('change_password')
      this.success = false
      let payload = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      }
      api
          .updatePassword(this.userId, payload)
          .then(response => {
            if (response.status === 200) {
                this.success = true
            }
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err, true)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      valid: false,
      errors: [],
      success: null,
      userId: null,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      rules: {
        required: [
          v => validation.isRequired(v) || 'required'
        ],
        newPassword: [
          v => (v && v.length >= constants.PASSWORD_MIN_LENGTH) || `must have a minimum length of ${constants.PASSWORD_MIN_LENGTH}`
        ],
        confirmNewPassword: [
          v => (this.newPassword === this.confirmNewPassword) || 'new password confirmation must match new password'
        ]
      }
    }
  }
}
</script>
